export function useUtils() {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function (this: any, ...args: []) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => fn.apply(this, args), ms)
    }
  }

  return {
    debounce,
  }
}
